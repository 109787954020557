import {
  DayOffOffInputType,
  RequestTimeOffInputType,
} from '@bas/hrm-domain/input-types';
import { useTimeOffTypesRequest } from '@bas/hrm-domain/requests';
import { useCalculateDaysOff } from '@bas/hrm-domain/utils';
import { useEmployeeStore } from '@bas/shared/state';
import { formatDate, formatHours } from '@bas/shared/utils';
import { Checkbox } from '@bas/ui/native/atoms';
import { Box, BoxProps, Typography } from '@bas/ui/native/base';
import {
  ReactHookFormCheckbox,
  ReactHookFormDatePickerField,
  ReactHookFormDropdown,
  ReactHookFormHoursTextField,
  ReactHookFormTextField,
} from '@bas/ui/native/molecules';
import dayjs from 'dayjs';
import {
  memo,
  ReactElement,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from 'react';
import { useFormContext, useWatch } from 'react-hook-form';
import { FormattedDateTimeRange, FormattedMessage, useIntl } from 'react-intl';

export type RequestTimeOffFormProps = BoxProps & {
  buildUp?: boolean;
};

const RequestTimeOffForm = ({
  style,
  buildUp,
  ...props
}: RequestTimeOffFormProps): ReactElement => {
  const { formatMessage } = useIntl();

  const employee = useEmployeeStore((state) => state.employee);

  const { setValue } = useFormContext<RequestTimeOffInputType>();

  const fullDay = useWatch<RequestTimeOffInputType, 'fullDay'>({
    name: 'fullDay',
  });

  const timeOffTypeId = useWatch<RequestTimeOffInputType, 'timeOffTypeId'>({
    name: 'timeOffTypeId',
  });

  const start = useWatch<RequestTimeOffInputType, 'start'>({
    name: 'start',
  });

  const end = useWatch<RequestTimeOffInputType, 'end'>({
    name: 'end',
  });

  const daysOff = useWatch<RequestTimeOffInputType, 'daysOff'>({
    name: 'daysOff',
  });

  const { data: timeOffTypesData } = useTimeOffTypesRequest({
    perPage: 9999,
  });

  const timeOffTypes = useMemo(
    () => timeOffTypesData?.data?.member || [],
    [timeOffTypesData?.data],
  );

  const timeOffTypesOptions = useMemo(
    () =>
      timeOffTypes
        .filter((type) => !buildUp || type.allowBuildUp)
        .map((timeOffType) => ({
          label: timeOffType.name,
          id: timeOffType.timeOffTypeId,
        })),
    [buildUp, timeOffTypes],
  );

  const currentTimeOffType = useMemo(
    () => timeOffTypes.find((t) => t.timeOffTypeId === timeOffTypeId),
    [timeOffTypeId, timeOffTypes],
  );

  const isSameDay = useMemo(
    () => !!start && !!end && dayjs(start).isSame(end, 'day'),
    [start, end],
  );

  const handleCalculatedDaysOff = useCallback(
    (newDaysOff: DayOffOffInputType[]) => {
      setValue('daysOff', newDaysOff, {
        shouldValidate: true,
        shouldDirty: true,
        shouldTouch: true,
      });
    },
    [setValue],
  );

  useCalculateDaysOff({
    daysOff,
    start,
    end,
    employee,
    onCalculatedDaysOff: handleCalculatedDaysOff,
  });

  useEffect(() => {
    if (!currentTimeOffType?.allowBuildUp || !buildUp) {
      let totalHours = 0;
      totalHours = daysOff.reduce((total, dayOff) => total + dayOff.hours, 0);

      setValue('effectiveHours', totalHours, {
        shouldValidate: true,
        shouldDirty: true,
        shouldTouch: true,
      });
    }
  }, [
    daysOff,
    setValue,
    currentTimeOffType?.allowBuildUp,
    buildUp,
    isSameDay,
    fullDay,
    start,
    end,
  ]);

  useEffect(() => {
    daysOff.forEach((dayOff, index) => {
      const newHours = dayjs(dayOff.end).diff(dayOff.start, 'hour', true);
      if (dayOff.hours !== newHours) {
        setValue(
          `daysOff.${index}`,
          { ...dayOff, hours: newHours },
          {
            shouldValidate: true,
            shouldDirty: true,
            shouldTouch: true,
          },
        );
      }
    });
  }, [daysOff, setValue]);

  const [overrideDaysOff, setOverrideDaysOff] = useState<string[]>([]);

  return (
    <Box flex={1} flexDirection="row" flexWrap="wrap" {...props} rowGap={3}>
      <Box flexBasis="100%">
        <ReactHookFormDropdown<RequestTimeOffInputType>
          items={timeOffTypesOptions}
          name="timeOffTypeId"
          label={formatMessage({
            id: `label.${buildUp ? 'hoursBuildUpType' : 'timeOffType'}`,
          })}
          light
        />
      </Box>
      <Box flexBasis="50%" pr={1}>
        <ReactHookFormDatePickerField<RequestTimeOffInputType>
          name="start"
          label={formatMessage({ id: 'label.startDate' })}
          light
          mode="date"
        />
      </Box>
      {!fullDay && (
        <Box flexBasis="50%" pl={1}>
          <ReactHookFormDatePickerField<RequestTimeOffInputType>
            name={'daysOff[0].start' as `daysOff.${number}.start`}
            label={formatMessage({ id: 'label.startTime' })}
            light
            mode="time"
          />
        </Box>
      )}
      <Box flexBasis="50%" pr={fullDay ? 0 : 1} pl={fullDay ? 1 : 0}>
        <ReactHookFormDatePickerField<RequestTimeOffInputType>
          name="end"
          label={formatMessage({ id: 'label.endDate' })}
          light
          mode="date"
        />
      </Box>
      {!fullDay && (
        <Box flexBasis="50%" pl={1}>
          <ReactHookFormDatePickerField<RequestTimeOffInputType>
            name={'daysOff[0].end' as `daysOff.${number}.end`}
            label={formatMessage({ id: 'label.endTime' })}
            light
            mode="time"
          />
        </Box>
      )}
      <Box flexBasis="50%" pr={1}>
        <ReactHookFormCheckbox<RequestTimeOffInputType>
          name="fullDay"
          label={formatMessage({ id: 'label.fullDay' })}
          size={24}
          disabled={!isSameDay}
        />
      </Box>
      <Box flexBasis="50%" pl={1}>
        <ReactHookFormHoursTextField<RequestTimeOffInputType>
          name="effectiveHours"
          label={formatMessage({ id: 'label.effectiveHours' })}
          light
          textAlign="left"
          readonly={!buildUp}
        />
      </Box>
      <Box flexBasis="100%">
        <ReactHookFormTextField<RequestTimeOffInputType>
          name="reason"
          label={formatMessage({ id: 'label.reason' })}
          light
          multiline
        />
      </Box>
      {!buildUp && (
        <Box flexBasis="100%">
          {!isSameDay && (
            <Typography color="white" variant="subtitle1">
              <FormattedMessage id="label.daysOff" />
            </Typography>
          )}
          <Box flex={1} flexDirection="column" flexWrap="wrap" rowGap={2}>
            {!isSameDay &&
              daysOff
                .map((dayOff, index) => ({ ...dayOff, realIndex: index }))
                .map((dayOff) => {
                  const dateKey = formatDate(dayjs(dayOff.start));
                  return (
                    <Box
                      rowGap={1}
                      flex={1}
                      flexDirection="row"
                      flexWrap="wrap"
                      key={dayOff.realIndex}
                      flexBasis="auto"
                    >
                      <Box flexBasis="50%" pr={1}>
                        <Typography color="white">
                          <FormattedDateTimeRange
                            from={dayjs(dayOff.start).toDate()}
                            to={dayjs(dayOff.end).toDate()}
                            year="numeric"
                            month="short"
                            day="numeric"
                            hour="numeric"
                            minute="numeric"
                          />
                        </Typography>
                      </Box>
                      <Box flexBasis="50%" pl={1}>
                        <Typography color="white">
                          {formatHours(dayOff.hours)}
                        </Typography>
                      </Box>
                      {overrideDaysOff.includes(dateKey) && (
                        <Box
                          flexBasis="100%"
                          flex={1}
                          flexDirection="row"
                          flexWrap="wrap"
                        >
                          <Box flexBasis="50%" pr={1}>
                            <ReactHookFormDatePickerField<RequestTimeOffInputType>
                              name={`daysOff.${dayOff.realIndex}.start`}
                              label={formatMessage({ id: 'label.startTime' })}
                              light
                              mode="time"
                            />
                          </Box>
                          <Box flexBasis="50%" pl={1}>
                            <ReactHookFormDatePickerField<RequestTimeOffInputType>
                              name={`daysOff.${dayOff.realIndex}.end`}
                              label={formatMessage({ id: 'label.endTime' })}
                              light
                              mode="time"
                            />
                          </Box>
                        </Box>
                      )}
                      <Box flexBasis="100%">
                        <Checkbox
                          checked={overrideDaysOff.includes(dateKey)}
                          label={formatMessage({
                            id: 'label.overrideStartAndEndTime',
                          })}
                          onCheck={() =>
                            setOverrideDaysOff((prev) =>
                              prev.includes(dateKey)
                                ? prev.filter((item) => item !== dateKey)
                                : [...prev, dateKey],
                            )
                          }
                          size={31}
                        />
                      </Box>
                    </Box>
                  );
                })}
          </Box>
        </Box>
      )}
    </Box>
  );
};

const MemoComponent = memo(RequestTimeOffForm, () => true);
export default MemoComponent;
