import { Holiday } from '@bas/planning-domain/models';
import { QueryOptionsWithKey } from '@bas/shared/requests';
import { formatDate } from '@bas/shared/utils';
import { Collection, Country, ErrorResponse } from '@bas/value-objects';
import { QueryKey, useQuery, UseQueryResult } from '@tanstack/react-query';
import axios, { AxiosError, AxiosResponse } from 'axios';

export type HolidaysByPeriodRequestProps = {
  country: Country;
  startDate: Date;
  endDate: Date;
};

type Response = AxiosResponse<Collection<Holiday>>;
type QueryKeyType = QueryKey & {
  [0]: 'holidays';
  [1]: 'list';
  [2]: string;
  [3]: string;
  [3]: string;
};

export const HolidaysByPeriodRequest = async ({
  country,
  startDate,
  endDate,
  ...params
}: HolidaysByPeriodRequestProps): Promise<Response> =>
  axios.get(
    `api/{tenantId}/planning/holidays/${country}/${formatDate(
      startDate,
    )}/${formatDate(endDate)}`,
    {
      params: { ...params },
    },
  );

export const useHolidaysByPeriodRequest = (
  request: HolidaysByPeriodRequestProps,
  options: QueryOptionsWithKey<
    Response,
    AxiosError<ErrorResponse>,
    Response,
    QueryKeyType
  > = {},
): UseQueryResult<Response, AxiosError<ErrorResponse>> =>
  useQuery<Response, AxiosError<ErrorResponse>, Response, QueryKeyType>({
    ...options,
    queryFn: async () => HolidaysByPeriodRequest({ ...request }),
    queryKey: [
      'holidays',
      'list',
      request.country,
      formatDate(request.startDate),
      formatDate(request.endDate),
    ],
  });
