import {
  CrmContactPerson,
  isBusinessRelation,
  isPrivateRelation,
  Relation,
} from '@bas/crm-domain/models';
import { MovingJobAddressInputTypeDefaultValues } from '@bas/project-domain/input-types';
import {
  isMovingJobCustomFurniture,
  isMovingJobStandardFurniture,
  MovingJob,
  MovingJobAddressType,
  MovingJobCustomFurniture,
  MovingJobRoom,
  MovingJobStandardFurniture,
} from '@bas/project-domain/models';
import {
  isMovingJobTaxationFurniture,
  isMovingJobTaxationStandardFurniture,
  MovingJobTaxationCustomer,
  MovingJobTaxationCustomFurniture,
  MovingJobTaxationFurniture,
  MovingJobTaxationRoom,
  MovingJobTaxationStandardFurniture,
  MovingJobTaxationWizardInitialValues,
  MovingJobTaxationWizardInput,
} from '@bas/taxation-tool-domain/models';
import {
  Gender,
  HowDidYouFindUs,
  isHowDidYouFindUs,
  Salutation,
} from '@bas/value-objects';
import { v7 } from 'uuid';

export const convertStandardFurnitureToIntakeFurniture = (
  furniture: MovingJobStandardFurniture,
  result: Omit<MovingJobTaxationFurniture, 'servicesPossible' | 'quantity'>,
): MovingJobTaxationStandardFurniture => ({
  ...result,
  furnitureType: 'furniture',
  furnitureTypeId: furniture.furnitureType.furnitureTypeId,
  furnitureVariantId: furniture.furnitureVariant?.variantTypeId,
  servicesPossible: furniture.furnitureType.servicesPossible,
  quantity: 1,
});

export const convertCustomFurnitureToIntakeFurniture = (
  furniture: MovingJobCustomFurniture,
  result: Omit<MovingJobTaxationFurniture, 'servicesPossible' | 'quantity'>,
): MovingJobTaxationCustomFurniture => ({
  quantity: 1,
  ...result,
  furnitureType: 'custom',
  label: furniture.label,
  cubicMeter: furniture.cubicMeter,
  servicesPossible: furniture.servicesPossible,
});

export const convertFurnitureToIntakeFurniture = (
  furniture: MovingJobStandardFurniture | MovingJobCustomFurniture,
): MovingJobTaxationFurniture => {
  const result: Omit<
    MovingJobTaxationFurniture,
    'servicesPossible' | 'quantity'
  > = {
    furnitureId: furniture.furnitureId,
    services: {
      0: furniture.services
        .map(({ internalType }) => internalType)
        .reduce(
          (acc, curr) => ({
            ...acc,
            [curr]: true,
          }),
          {},
        ),
    },
    selected: true,
    furnitureType: 'furniture',
  };

  if (isMovingJobCustomFurniture(furniture)) {
    return convertCustomFurnitureToIntakeFurniture(furniture, result);
  }

  return convertStandardFurnitureToIntakeFurniture(furniture, result);
};

export const convertRoomToIntakeRoom = (
  room: MovingJobRoom,
): MovingJobTaxationRoom => {
  const furniture: MovingJobTaxationFurniture[] = [];
  const mappedFurniture: MovingJobTaxationFurniture[] = room.furniture
    .map((item) =>
      isMovingJobStandardFurniture(item) || isMovingJobCustomFurniture(item)
        ? convertFurnitureToIntakeFurniture(item)
        : undefined,
    )
    .filter((f): f is MovingJobTaxationFurniture =>
      isMovingJobTaxationFurniture(f),
    );

  mappedFurniture.forEach((f) => {
    if (isMovingJobTaxationStandardFurniture(f)) {
      const existing = furniture
        .filter(isMovingJobTaxationStandardFurniture)
        .find(
          ({ furnitureTypeId, furnitureVariantId }) =>
            furnitureTypeId === f.furnitureTypeId &&
            f.furnitureVariantId === furnitureVariantId,
        );

      if (!existing) {
        furniture.push(f);
      } else {
        const newFurniture: MovingJobTaxationStandardFurniture = {
          ...existing,
          quantity: existing.quantity + 1,
        };
        // eslint-disable-next-line prefer-destructuring
        newFurniture.services[newFurniture.quantity - 1] = f.services[0];

        furniture[furniture.indexOf(existing)] = newFurniture;
      }
    } else {
      furniture.push(f);
    }
  });

  return {
    roomId: room.roomId,
    roomTypeId: room.roomType.roomTypeId,
    name: room.name,
    notes: room.notes,
    selected: true,
    finished: true,
    photos: room.photos,
    furniture,
    inventoryItems: room.inventoryItems,
  };
};

export const convertRelationToCustomer = (
  relation: Relation,
  projectContactPerson?: CrmContactPerson,
): MovingJobTaxationCustomer => {
  let { howDidYouFindUs } = relation;
  let howDidYouFindUsOther = '';
  if (howDidYouFindUs) {
    if (!isHowDidYouFindUs(howDidYouFindUs)) {
      howDidYouFindUsOther = howDidYouFindUs;
      howDidYouFindUs = HowDidYouFindUs.OTHER;
    }
  } else {
    howDidYouFindUs = HowDidYouFindUs.UNKNOWN;
  }

  if (projectContactPerson || isBusinessRelation(relation)) {
    const contactPerson = projectContactPerson || relation.primaryContactPerson;
    if (contactPerson) {
      const {
        salutation,
        gender,
        personName: { firstName, middleName, lastName },
      } = contactPerson.personalInformation;

      return {
        customerId: relation.relationId,
        relationType: relation.relationType,
        salutation: salutation === null ? undefined : salutation,
        gender: gender === null ? undefined : gender,
        firstName: firstName === null ? '' : firstName,
        middleName: middleName === null ? '' : middleName,
        lastName: lastName || '',
        emailAddress: contactPerson.primaryContactInformation?.emailAddress,
        phoneNumber: contactPerson.primaryContactInformation?.phoneNumber,
        mobileNumber: contactPerson.primaryContactInformation?.mobileNumber,
        language: relation.language,
        companyName: isBusinessRelation(relation)
          ? relation.companyInformation.companyName
          : '',
        vatNumber: isBusinessRelation(relation)
          ? relation.companyInformation.vatNumber
          : '',
        howDidYouFindUsOther,
        howDidYouFindUs,
      };
    }
  }

  if (isPrivateRelation(relation)) {
    const {
      salutation,
      gender,
      personName: { firstName, middleName, lastName },
    } = relation.personalInformation;
    return {
      customerId: relation.relationId,
      relationType: relation.relationType,
      salutation: salutation === null ? Salutation.UNKNOWN : salutation,
      gender: gender === null ? Gender.UNKNOWN : gender,
      firstName: firstName === null ? '' : firstName,
      middleName: middleName === null ? '' : middleName,
      lastName: lastName || '',
      emailAddress: relation.primaryContactInformation?.emailAddress,
      phoneNumber: relation.primaryContactInformation?.phoneNumber,
      mobileNumber: relation.primaryContactInformation?.mobileNumber,
      language: relation.language,
      howDidYouFindUsOther,
      howDidYouFindUs,
    };
  }

  return MovingJobTaxationWizardInitialValues().customer;
};

export const convertMovingJobToMovingJobTaxation = (
  project: MovingJob,
): MovingJobTaxationWizardInput => {
  const addresses = project.addresses
    .sort((addressA, addressB) => {
      if (
        addressA.primary &&
        addressB.primary &&
        addressA.addressType === MovingJobAddressType.FROM
      ) {
        return -1;
      }

      if (addressA.primary && !addressB.primary) {
        return -1;
      }

      return addressA.addressType === MovingJobAddressType.FROM ? -1 : 1;
    })
    .map(({ floor, ...address }) => ({
      ...address,
      floor: typeof floor === 'number' ? floor : null,
      unknownAddress:
        address.primary &&
        address.addressType === MovingJobAddressType.TO &&
        !address.zipCode,
    }));

  if (addresses.length === 1) {
    addresses[1] = {
      ...MovingJobAddressInputTypeDefaultValues(),
      floor: null,
      addressId: v7(),
      addressType: MovingJobAddressType.TO,
      primary: true,
      deliverBoxes: true,
      unknownAddress: true,
    };
  }

  return {
    ...MovingJobTaxationWizardInitialValues(),
    projectId: project.projectId,
    identityId: project.identityId,
    packageId: project.package.packageId,
    customer: convertRelationToCustomer(
      project.customer,
      project.projectContactPerson,
    ),
    pricingType: project.pricingType,
    addresses,
    rooms: project.rooms.map((room) => convertRoomToIntakeRoom(room)),
    services: project.services.map((serviceType) => ({
      internalType: serviceType.internalType,
      serviceTypeId: serviceType.serviceTypeId,
      serviceTypeName: serviceType.serviceTypeName,
      translatedNames: serviceType.translatedNames || [],
      selected: true,
    })),
    notes: project.notes || '',
    customerNotes: project.customerNotes || '',
    storageNotes: project.storageNotes || '',
    billingMoments: project.billingMoments,
    neededInventoryItems: project.neededInventoryItems.map(
      ({ inventoryItem: { inventoryItemId }, quantity }) => ({
        inventoryItemId,
        quantity,
      }),
    ),
    estimate: undefined,
    quote: undefined,
  };
};
