import { RelationType } from '@bas/crm-domain/models';
import {
  ErrorResponse,
  Gender,
  HowDidYouFindUs,
  Salutation,
  Uuid,
} from '@bas/value-objects';
import {
  useMutation,
  UseMutationOptions,
  UseMutationResult,
} from '@tanstack/react-query';
import axios, { AxiosError, AxiosResponse } from 'axios';

export type RenameRelationMutationProps = {
  relationId: Uuid;
  relationType: RelationType;
  firstName?: string | null;
  middleName?: string | null;
  gender?: Gender | null;
  salutation?: Salutation | null;
  lastName?: string | null;
  companyName?: string | null;
  cocNumber?: string | null;
  vatNumber?: string | null;
  rsinNumber?: string | null;
  howDidYouFindUs?: HowDidYouFindUs | null;
  howDidYouFindUsOther?: string | null;
};

export const RenameRelationMutation = async ({
  relationId,
  howDidYouFindUs,
  howDidYouFindUsOther,
  ...values
}: RenameRelationMutationProps): Promise<AxiosResponse> =>
  axios.put(`api/{tenantId}/relations/${relationId}/rename`, {
    relationId,
    howDidYouFindUs:
      howDidYouFindUs === HowDidYouFindUs.OTHER
        ? howDidYouFindUsOther
        : howDidYouFindUs,
    ...values,
  });

export const useRenameRelationMutation = (
  options: UseMutationOptions<
    AxiosResponse,
    AxiosError<ErrorResponse>,
    RenameRelationMutationProps
  > = {},
): UseMutationResult<
  AxiosResponse,
  AxiosError<ErrorResponse>,
  RenameRelationMutationProps
> =>
  useMutation<
    AxiosResponse,
    AxiosError<ErrorResponse>,
    RenameRelationMutationProps
  >({
    mutationFn: RenameRelationMutation,
    throwOnError: false,
    ...options,
  });
