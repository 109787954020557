export * from './addDeclarationMutation';
export * from './addClaAllowanceRuleMutation';
export * from './changeClaAllowanceRuleMutation';
export * from './enableOrDisableClaAllowanceRuleMutation';
export * from './addClaCompensationRuleMutation';
export * from './changeClaCompensationRuleMutation';
export * from './enableOrDisableClaCompensationRuleMutation';
export * from './changeEmployeesAttributesMutation';
export * from './changeEmployeeRolesMutation';
export * from './addEmployeeCertificateMutation';
export * from './addEmployeeContractMutation';
export * from './addEmployeeMutation';
export * from './addEmployeeOccasionalAvailabilityMutation';
export * from './addNationalHolidayMutation';
export * from './changeEmployeeAttributeMutation';
export * from './addEmployeeAttributeMutation';
export * from './addTeamMemberMutation';
export * from './addTimeTypeMutation';
export * from './approveDeclarationMutation';
export * from './approveHoursMutation';
export * from './approveTimeEntryMutation';
export * from './approveTimeOffRegistrationMutation';
export * from './cancelDeclarationMutation';
export * from './cancelTimeOffRegistrationMutation';
export * from './changeDeclarationCategoryMutation';
export * from './changeDeclarationMutation';
export * from './changeEmployeeAvailabilityMutation';
export * from './changeEmployeeCertificatesMutation';
export * from './changeEmployeeContractsMutation';
export * from './changeEmployeeDriversLicenseMutation';
export * from './changeEmployeeInformationMutation';
export * from './changeEmployeeOccasionalAvailabilityMutation';
export * from './changeEmployeeProfilePictureMutation';
export * from './changeEmployeeSkillsMutation';
export * from './changeEmployeeWorkingHoursMutation';
export * from './changeEmploymentInformationMutation';
export * from './changeHoursMutation';
export * from './changeRelatedInformationOfDeclarationMutation';
export * from './changeTimeOffRegistrationMutation';
export * from './changeTimeOffTypeMutation';
export * from './changeTimeTypeMutation';
export * from './createDeclarationCategoryMutation';
export * from './createTeamMutation';
export * from './createTimeOffTypeMutation';
export * from './disableDeclarationCategoryMutation';
export * from './disableTimeOffTypeMutation';
export * from './disableTimeTypeMutation';
export * from './employeeMovedMutation';
export * from './enableDeclarationCategoryMutation';
export * from './enableTimeOffTypeMutation';
export * from './enableTimeTypeMutation';
export * from './enterHoursForEventMutation';
export * from './enterHoursMutation';
export * from './enterNormalHoursMutation';
export * from './enterTimeMutation';
export * from './markDeclarationAsPaidMutation';
export * from './rejectDeclarationMutation';
export * from './rejectTimeEntryMutation';
export * from './rejectTimeOffRegistrationMutation';
export * from './removeEmployeeOccasionalAvailabilityMutation';
export * from './removeHoursMutation';
export * from './removeNationalHolidayMutation';
export * from './disableEmployeeAttributeMutation';
export * from './enableEmployeeAttributeMutation';
export * from './removeTeamMemberMutation';
export * from './removeTeamMutation';
export * from './removeTimeEntryMutation';
export * from './requestTimeOffMutation';
export * from './setTimeOffBalanceInBulkMutation';
export * from './syncTimeEntriesMutation';
export * from './updateTeamMutation';
