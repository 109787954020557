import {
  calculateRecurringEventsWithingPeriod,
  Event,
  isProjectEvent,
} from '@bas/planning-domain/models';
import {
  EventListItem,
  LoadingEventListItem,
} from '@bas/planning-domain/native/molecules';
import {
  useEventsByEmployeeIdRequest,
  useRecurringEventsByEmployeeIdRequest,
} from '@bas/planning-domain/requests';
import { useProjectsByProjectIdsRequest } from '@bas/project-domain/requests';
import { useEmployeeStore, useTenantStore } from '@bas/shared/state';
import {
  capitalizeFirstLetter,
  formatDate,
  getProjectIdsFromEvents,
} from '@bas/shared/utils';
import { isMobileAppFeature } from '@bas/tenant-domain/models';
import { Box, Typography } from '@bas/ui/native/base';
import { hasEventPage, Uuid } from '@bas/value-objects';
import dayjs from 'dayjs';
import dayjsBusinessDays from 'dayjs-business-days2';
import { router } from 'expo-router';
import * as React from 'react';
import { ReactElement, useCallback, useMemo } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { RefreshControl, ScrollView, StyleSheet, View } from 'react-native';
import { SafeAreaView } from 'react-native-safe-area-context';

dayjs.extend(dayjsBusinessDays);

const styles = StyleSheet.create({
  container: {
    flex: 1,
    paddingLeft: 20,
    paddingRight: 20,
    paddingTop: 35,
  },
  flexContainer: {
    flex: 1,
  },
  eventsContainer: {
    marginBottom: 50,
  },
  events: {
    marginTop: 20,
  },
});

const MobileEmployeeCalendarScreen = (): ReactElement => {
  const tenantState = useTenantStore((state) => state.tenant);
  const mobileAppFeature = tenantState?.mobileAppFeature;
  const employeeState = useEmployeeStore((state) => state.employee);
  const { formatDate: formatWeekday } = useIntl();

  let daysAhead = 1;
  let timeMarginBeforeEventInMinutes = 0;
  let fromWhatTimeWillNewEventsBeAvailable = 16;
  if (mobileAppFeature && isMobileAppFeature(mobileAppFeature)) {
    daysAhead = mobileAppFeature.howManyDaysCanEmployeesLookAhead;
    timeMarginBeforeEventInMinutes =
      mobileAppFeature.timeMarginBeforeEventInMinutes;
    fromWhatTimeWillNewEventsBeAvailable =
      mobileAppFeature.fromWhatTimeWillNewEventsBeAvailable;
  }

  const today = useMemo(() => dayjs().startOf('day').utc(true).toDate(), []);

  const untilDate = useMemo(
    () => dayjs().businessDaysAdd(daysAhead).toDate(),
    [daysAhead],
  );

  const showEventsBefore = useMemo(() => {
    if (
      dayjs().isAfter(
        dayjs()
          .startOf('day')
          .add(fromWhatTimeWillNewEventsBeAvailable, 'hour'),
      )
    ) {
      return dayjs().businessDaysAdd(daysAhead);
    }

    return dayjs().businessDaysAdd(daysAhead - 1 > 0 ? daysAhead - 1 : 0);
  }, [daysAhead, fromWhatTimeWillNewEventsBeAvailable]);

  const {
    isInitialLoading: isLoadingEvents,
    isFetching: isFetchingEvents,
    data: eventsResponse,
    refetch: refetchEvents,
  } = useEventsByEmployeeIdRequest(
    {
      employeeId: employeeState?.employeeId || '',
      fromDate: today,
      untilDate,
    },
    {
      enabled: !!employeeState?.employeeId,
    },
  );

  const {
    isLoading: isLoadingRecurringEvents,
    isFetching: isFetchingRecurringEvents,
    data: recurringEventsResponse,
    refetch: refetchRecurringEvents,
  } = useRecurringEventsByEmployeeIdRequest(
    {
      employeeId: employeeState?.employeeId || '',
      fromDate: today,
      untilDate,
    },
    {
      enabled: !!employeeState?.employeeId,
    },
  );

  const recurringEvents = useMemo(
    () =>
      (recurringEventsResponse?.data.member || []).reduce(
        (acc: Event[], event: Event) => [
          ...acc,
          ...calculateRecurringEventsWithingPeriod(event, today, untilDate),
        ],
        [],
      ),
    [recurringEventsResponse, today, untilDate],
  );
  const { isPending: isLoadingIntakeEvents, data: intakeEventsResponse } =
    useEventsByEmployeeIdRequest(
      {
        employeeId: employeeState?.employeeId || '',
        fromDate: today,
        intake: 'true',
      },
      {
        enabled: !!employeeState?.employeeId,
      },
    );

  const projectIds = useMemo(
    () => [
      ...getProjectIdsFromEvents(eventsResponse),
      ...getProjectIdsFromEvents(recurringEventsResponse),
      ...getProjectIdsFromEvents(intakeEventsResponse),
    ],
    [recurringEventsResponse, eventsResponse, intakeEventsResponse],
  );

  const {
    isInitialLoading: isLoadingProjects,
    isFetching: isFetchingProjects,
    data: projectsData,
    refetch: refetchProjects,
  } = useProjectsByProjectIdsRequest({
    projectIds,
    perPage: projectIds.length,
  });

  const isFetching = useMemo(
    () => isFetchingProjects || isFetchingEvents || isFetchingRecurringEvents,
    [isFetchingEvents, isFetchingProjects, isFetchingRecurringEvents],
  );

  const events = useMemo(() => {
    const result = eventsResponse?.data.member ?? [];
    const intakeEvents = intakeEventsResponse?.data.member ?? [];
    intakeEvents.forEach((intakeEvent) => {
      const event = result.find((e) => e.eventId === intakeEvent.eventId);
      if (!event) {
        result.push(intakeEvent);
      }
    });

    return [...result, ...recurringEvents]
      .sort((a, b) =>
        dayjs(a.travelStart || a.start).isBefore(
          dayjs(b.travelStart || b.start),
        )
          ? -1
          : 1,
      )
      .filter((event) =>
        dayjs(event.start).isSameOrBefore(showEventsBefore, 'day'),
      );
  }, [
    eventsResponse?.data.member,
    intakeEventsResponse?.data.member,
    recurringEvents,
    showEventsBefore,
  ]);

  const projects = useMemo(
    () => projectsData?.data?.member || [],
    [projectsData?.data],
  );

  const onRefresh = useCallback(() => {
    refetchEvents();
    refetchRecurringEvents();
    refetchProjects();
  }, [refetchEvents, refetchProjects, refetchRecurringEvents]);

  const onPressEvent = useCallback(
    (event: { eventId: Uuid }, clickedDate: Date) => {
      router.navigate(
        `/event/${event.eventId}/?selectedDate=${formatDate(clickedDate)}`,
      );
    },
    [],
  );

  const todayEvents = useMemo(
    () =>
      events.filter((event) => dayjs(event.start).isSame(new Date(), 'day')),
    [events],
  );

  const tomorrowEvents = useMemo(
    () =>
      events.filter((event) =>
        dayjs(event.start).isSame(dayjs().add(1, 'day'), 'day'),
      ),

    [events],
  );

  const laterEvents = useMemo(
    () =>
      events.filter((event) =>
        dayjs(event.start).isAfter(dayjs().add(1, 'day'), 'day'),
      ),
    [events],
  );

  const groupedEvents: {
    [key: string]: Event[];
  } = useMemo(() => {
    const result: {
      [key: string]: Event[];
    } = {};
    laterEvents.forEach((event) => {
      let date = formatDate(event.start);
      if (
        dayjs(event.start).isSameOrAfter(dayjs().add(1, 'week'), 'day') &&
        dayjs(event.start).isSameOrBefore(dayjs().add(2, 'week'), 'day')
      ) {
        date = 'nextWeek';
      }

      if (typeof result[date] === 'undefined') {
        result[date] = [];
      }

      result[date].push(event);
    });
    return result;
  }, [laterEvents]);

  const renderEvent = useCallback(
    (event: Event) => (
      <EventListItem
        key={event.eventId}
        onPress={
          hasEventPage(event.eventType)
            ? () => onPressEvent(event, event.start)
            : undefined
        }
        timeMarginBeforeEventInMinutes={timeMarginBeforeEventInMinutes}
        planningEvent={event}
        project={
          isProjectEvent(event)
            ? projects.find(({ projectId }) => projectId === event.projectId)
            : undefined
        }
      />
    ),
    [onPressEvent, projects, timeMarginBeforeEventInMinutes],
  );

  return (
    <SafeAreaView style={styles.flexContainer}>
      <View style={styles.container}>
        <ScrollView
          style={styles.flexContainer}
          refreshControl={
            <RefreshControl refreshing={isFetching} onRefresh={onRefresh} />
          }
        >
          <View style={styles.eventsContainer}>
            <Typography variant="h5" fontWeight={700}>
              <FormattedMessage id="label.today" />
            </Typography>
            <View style={styles.events}>
              {isLoadingProjects ||
              isLoadingEvents ||
              isLoadingIntakeEvents ||
              isLoadingRecurringEvents ? (
                <Box style={styles.events}>
                  <LoadingEventListItem />
                </Box>
              ) : (
                todayEvents.length === 0 && (
                  <Typography>
                    <FormattedMessage id="mobileApp.noEventsForToday" />
                  </Typography>
                )
              )}
              {todayEvents.map(renderEvent)}
            </View>
          </View>
          {tomorrowEvents.length > 0 && (
            <View style={styles.eventsContainer}>
              <Typography variant="h5" fontWeight={700}>
                <FormattedMessage id="label.tomorrow" />
              </Typography>
              <View style={styles.events}>
                {tomorrowEvents.map(renderEvent)}
              </View>
            </View>
          )}
          {Object.keys(groupedEvents).map((date) => (
            <View style={styles.eventsContainer} key={date}>
              <Typography variant="h5" fontWeight={700}>
                {date === 'nextWeek' ? (
                  <FormattedMessage id="label.nextWeek" />
                ) : (
                  capitalizeFirstLetter(
                    formatWeekday(date, {
                      weekday: 'long',
                      day:
                        dayjs(date).diff(dayjs(), 'day') > 6
                          ? 'numeric'
                          : undefined,
                      month:
                        dayjs(date).diff(dayjs(), 'day') > 6
                          ? 'long'
                          : undefined,
                    }),
                  )
                )}
              </Typography>
              <View style={styles.events}>
                {groupedEvents[date].map(renderEvent)}
              </View>
            </View>
          ))}
        </ScrollView>
      </View>
    </SafeAreaView>
  );
};

export default MobileEmployeeCalendarScreen;
